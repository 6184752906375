@import '../variables.scss';

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    line-height: 1.2;
    overscroll-behavior-y: contain;
}

* {
    font-family: "Quicksand", sans-serif;
}

strong, b {
    font-weight: 500;
}

*, ::before, ::after {
    box-sizing: border-box;
}

p {
    margin: 0 0 1rem;
}

#root {
    display: flex;
    height: 100%;
    position: relative;
}

h1, .h1 {
    font-size: 2rem;
}
h2, .h2 {
    font-size: 1.5rem;
}
h3, .h3 {
    font-size: 1.17rem;
}
h4, .h4 {
    font-size: 1rem;
}
h5, .h5 {
    font-size: 0.83rem;
}
h6, .h6 {
    font-size: 0.67rem;
}