@import '../../variables.scss';

.LessonView {
    padding: 1rem;
    position: relative;
    flex: 1;

    &.is--busy {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    h3 {
        margin-top: 0;
    }

    .Back {
        font-size: 14px;
        text-decoration: none;
        color: #000;
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        span {
            background-color: $primary;
            color: #FFFFFF;
            width: 25px;
            height: 25px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 35%;
            vertical-align: 0;
            margin: 0 8px 0 0;
            svg {
                width: 8px;
                height: 8px;
                transform: scale(-1);
            }
        }
    }

    .CriteriaModal {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 1rem;
        background-color: #FFF;

        .Back {
            margin-top: 1rem;
        }
    }

    .LessonViewDetail {
        margin-bottom: 1rem;

        .LessonAttendanceRow {
            margin-bottom: 1px;
            .LessonAttendanceRowHeader {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                background-color: $primary-light;
                padding: 0.5rem;

                .name {
                    font-size: 12px;
                    font-weight: bold;
                    margin-right: auto;
                }

                .toggle {
                    border: none;
                    background: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 25px;
                    height: 25px;
                    svg {
                        transform: rotate(90deg);
                    }
                }
            }
            .LessonAttendanceRowBody {
                display: none;
                padding: 1rem 0.5rem;

                a {
                    text-decoration: none;
                    color: $primary;
                }

                dl {
                    font-size: 14px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin: 0;
                    dt {
                        flex-basis: 35%;
                        font-weight: bold;
                        padding: 3px 0;
                    }
                    dd {
                        flex-basis: 65%;
                        margin-inline-start: 0;
                        padding: 3px 0;
                    }
                }

                button {
                    margin: 1rem 0 0;
                    display: block;
                    background-color: $dark-grey;
                    border-radius: 5px;
                    border: none;
                    padding: 0.5rem 1rem;
                    color: #FFFFFF;
                    font-weight: 500;
                }

                textarea {
                    width: 100%;
                    padding: 0.5rem;
                    margin-top: 1rem;
                }
            }
            &.open {
                .LessonAttendanceRowHeader {
                    .toggle {
                        svg {
                            transform: rotate(-90deg);
                        }
                    }
                }
                .LessonAttendanceRowBody {
                    display: block;

                }
            }
        }
    }

    .LessonCriteriaRow {
        margin-bottom: 1px;
        .LessonCriteriaRowHeader {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            background-color: $primary-light;
            padding: 0.5rem;

            .name {
                font-size: 12px;
                font-weight: bold;
                margin-right: auto;
            }

            .toggle {
                border: none;
                background: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25px;
                height: 25px;
                svg {
                    transform: rotate(90deg);
                }
            }
        }
        .LessonCriteriaRowBody {
            display: none;
            padding: 1rem 0.5rem;

            textarea {
                width: 100%;
                padding: 0.5rem;
            }
        }
        &.open {
            .LessonCriteriaRowHeader {
                .toggle {
                    svg {
                        transform: rotate(-90deg);
                    }
                }
            }
            .LessonCriteriaRowBody {
                display: block;

            }
        }
    }
}