@import '../../variables.scss';

.Lessons {
    padding: 1rem;
    flex: 1;

    h3 {
        margin-bottom: 0.5rem;
    }
    
    .LessonsPagination {
        display: none;
        align-items: center;
        .Previous, .Next {
            border-radius: 5px;
            border: none;
            padding: 1rem;
            margin: 0;
            display: block;
            background-color: $secondary;
            color: $primary;
            font-size: 1rem;
            svg {
                width: 18px;
                height: 18px;
                vertical-align: -4px;
            }
        }
        .Previous {
            svg {
                margin: 0 1rem 0 0;
                transform: scale(-1);
            }
        }
        .Next {
            margin: 0 0 0 auto;
            svg {
                margin: 0 0 0 1rem;
            }
        }
    }

    .refresh-view {
        height: 100%;
    }

    .LessonsListItem {
        margin-top: 0.5rem;
        padding: 1rem;
        border: none;
        border-radius: 0;
        display: flex;
        align-items: center;
        text-align: left;
        background-color: #d8f9f1;
        font-size: 14px;
        font-weight: 400;
        width: 100%;
        gap: 10px;
        &.Type-single {
            background-color: #d5f4f9;
        }

        .badge {
            padding: 0.5rem 1rem;
            background-color: #0dcaf0;
            font-weight: 700;
            color: #FFFFFF;
            text-align: center;
        }

        .instructor {
            display: none;
        }

        & > span:last-child {
            background: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            margin-left: auto;
        }

        div {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        @media (min-width: 450px) {
            div {
                display: flex;
                align-items: center;
            }
        }
    }
}